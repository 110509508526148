import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Table from "@/components/table";
import Search from "@/components/inputs/search";
import PageTitle from "@/components/pageTitle";
import { UserDto } from "@/global/dtos/user.dto";
import Button from "@/components/buttons";
import useLoading from "@/hooks/useLoading";
import { createSeller, getSellers } from "@/communication/user";
import {
  StyledActionButtons,
  StyledForm,
  StyledInputsContainer,
  StyledInputsRowContainer,
  StyledPageHeader,
} from "./style";
import { ReactComponent as ContentIcon } from "@/assets/icons/navbar/content.svg";
import Modal from "@/components/modal";
import useToggle from "@/hooks/useToggle";
import InputField from "@/components/inputs/inputField";
import { useTouchField } from "@/hooks/useTouchField";
import useIsEmail from "@/hooks/validation/useIsEmail";
import SwitchField from "@/components/inputs/switch";
import SelectField from "@/components/select";
import { AgencyDto } from "@/global/dtos/agency.dto";
import { getAllAgencies } from "@/communication/agencies";

const CreatorsList: FunctionComponent = () => {
  const [usersList, setUsersList] = useState<UserDto[] | null>(null);
  const { showLoading, hideLoading } = useLoading();
  const [searchEvent, setSearchEvent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const modal = useToggle();
  const [agencyList, setAgencyList] = useState<AgencyDto[] | null>(null);

  const isFormValid = true;

  type FormValues = {
    displayName: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    bio: string;
    instaHandle: string;
    tiktokHandle: string;
    profilePicture?: string;
    useAgency?: boolean;
    agencyName?: string;
  };

  const [formValues, setFormValues] = useState<FormValues>({
    displayName: "",
    email: "",
    password: "Test123#",
    firstName: "",
    lastName: "",
    bio: "",
    instaHandle: "",
    tiktokHandle: "",
    useAgency: false,
    agencyName: "",
  });

  const emailErrorMessage = useIsEmail(formValues.email);

  const { isFieldTouched, handleFieldTouch } = useTouchField<FormValues>();

  const handleValueChange = (e): void => {
    setSearchEvent(e);
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    getSellers("READY").then((res: any) => {
      setUsersList && setUsersList(res.data);
    });
    getAllAgencies().then((res: any) => {
      setAgencyList && setAgencyList(res.data);
    });
  }, []);

  const handleRowClick = (e): any => {
    // e.stopPropagation();
    console.log(e);
  };

  const handleChange = (fieldName: keyof FormValues, value: string): void => {
    handleFieldTouch(fieldName);
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    if (fieldName === "firstName")
      setFormValues((prevValues) => ({
        ...prevValues,
        ["firstName"]: value,
      }));
    if (fieldName === "lastName")
      setFormValues((prevValues) => ({
        ...prevValues,
        ["lastName"]: value,
      }));
    if (fieldName === "bio")
      setFormValues((prevValues) => ({ ...prevValues, ["bio"]: value }));
    if (fieldName === "agencyName")
      setFormValues((prevValues) => ({
        ...prevValues,
        ["agencyName"]: value,
      }));
  };

  const handleCreate = (): void => {
    showLoading();
    const { useAgency, ...rest } = formValues;
    createSeller(rest)
      .then(async (res: any) => {
        hideLoading();
        toast.success("Creator added!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        modal.close();
        getSellers("READY").then((res: any) => {
          setUsersList && setUsersList(res.data);
        });

        if (formValues.displayName)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["displayName"]: "",
          }));
        if (formValues.email)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["email"]: "",
          }));
        if (formValues.firstName)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["firstName"]: "",
          }));
        if (formValues.lastName)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["lastName"]: "",
          }));
        if (formValues.bio)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["bio"]: "",
          }));
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
  };

  return (
    <>
      <Modal isOpen={modal.isOpen} handleClose={modal.close} width="44rem">
        <StyledForm>
          <StyledInputsContainer>
            <StyledInputsRowContainer>
              <InputField
                type="text"
                label="Email"
                value={formValues.email}
                errorMessage={isFieldTouched("email") ? emailErrorMessage : ""}
                onChange={(e) => handleChange("email", e.target.value)}
                placeholder="Email"
              />
            </StyledInputsRowContainer>
            <StyledInputsRowContainer>
              <InputField
                type="text"
                label="First name"
                value={formValues.firstName}
                onChange={(e) => handleChange("firstName", e.target.value)}
                placeholder="First name"
              />
              <InputField
                type="text"
                label="lastName"
                value={formValues.lastName}
                onChange={(e) => handleChange("lastName", e.target.value)}
                placeholder="Last name"
              />
            </StyledInputsRowContainer>
            <InputField
              type="text"
              label="Display Name"
              value={formValues.displayName}
              onChange={(e) => handleChange("displayName", e.target.value)}
              placeholder="Display name"
            />
            <StyledInputsRowContainer>
              <InputField
                type="text"
                label="Instagram handle"
                value={formValues.instaHandle}
                onChange={(e) => handleChange("instaHandle", e.target.value)}
                placeholder="Instagram handle"
              />
              <InputField
                type="text"
                label="TikTok handle"
                value={formValues.tiktokHandle}
                onChange={(e) => handleChange("tiktokHandle", e.target.value)}
                placeholder="TikTok handle"
              />
            </StyledInputsRowContainer>
            <SwitchField
              onChange={(value: boolean) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  ["useAgency"]: value,
                }));
              }}
              defaultChecked={formValues.useAgency || false}
              label="Using an Agency"
            />
            {formValues.useAgency && (
              <SelectField
                label="Agency"
                options={agencyList?.map((agency) => {
                  return { value: agency.name, label: agency.name };
                })}
                onChange={(e) => handleChange("agencyName", e.value)}
                placeholder={formValues.agencyName}
              />
            )}
            <StyledActionButtons>
              <Button
                disabled={!isFormValid}
                width="152px"
                variant="outlined"
                onClick={modal.close}
              >
                CANCEL
              </Button>
              <Button
                disabled={!isFormValid}
                width="152px"
                variant="solid"
                onClick={handleCreate}
              >
                SAVE
              </Button>
            </StyledActionButtons>
          </StyledInputsContainer>
        </StyledForm>
      </Modal>
      <div className="page">
        <StyledPageHeader>
          <PageTitle title="Creators" icon={<ContentIcon />} />
          <Search placeholder="Search" onChange={handleValueChange} />
          <Button
            width="152px"
            variant="solid"
            onClick={() => {
              modal.open();
            }}
          >
            Add new
          </Button>
        </StyledPageHeader>
        <Table
          items={
            usersList
              ?.filter(
                (user) =>
                  user.firstName
                    ?.toLowerCase()
                    .includes(searchValue?.toLowerCase() ?? "") ||
                  user.lastName
                    ?.toLowerCase()
                    .includes(searchValue?.toLowerCase() ?? "") ||
                  user.email
                    ?.toLowerCase()
                    .includes(searchValue?.toLowerCase() ?? "")
              )
              .map((user) => ({
                id: user.id,
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                email: user.email,
                instaHandle: user.instaHandle || "",
                tiktokHandle: user.tiktokHandle || "",
                displayName: user.displayName,
              })) || []
          }
          columns={[
            { accessorKey: "firstName", header: "First name" },
            { accessorKey: "lastName", header: "Last name" },
            { accessorKey: "email", header: "Email" },
            { accessorKey: "instaHandle", header: "Instagram" },
            { accessorKey: "tiktokHandle", header: "TikTok" },
            { accessorKey: "displayName", header: "Display name" },
          ]}
          handleRowClick={(e) => handleRowClick(e)}
        />
      </div>
    </>
  );
};

export default CreatorsList;
