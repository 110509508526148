import styled from 'styled-components';
export const StyledPageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledForm = styled.div`
    background: ${(props) => props.theme.palette.lightBgColor};
`;

export const StyledInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    textarea {
        height: 160px;
    }
`;

export const StyledInputsRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const StyledActionButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;