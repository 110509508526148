import { ProductDto, UpdateProductDto } from '@/global/dtos/product.dto';
import fetch from './fetch';
import routes from './routes';

export const getAllProducts = async (sortBy?: string): Promise<ProductDto[]> => {
    return fetch({
        method: 'GET',
        url: routes.products.list,
        headers: { 'Content-Type': 'application/json' },
        params: { sortBy }
    });
};

export const getProduct = async (id: string): Promise<ProductDto> => {
    return fetch({
        method: 'GET',
        url: routes.products.single(id),
        headers: { 'Content-Type': 'application/json' },
    });
};

export const updateProduct = async (id: string, product: UpdateProductDto): Promise<ProductDto> => {
    return fetch({
        method: 'PATCH',
        url: routes.products.single(id),
        headers: { 'Content-Type': 'application/json' },
        data: product,
    });
};

export const createProduct = async (product: UpdateProductDto): Promise<ProductDto> => {
    return fetch({
        method: 'POST',
        url: routes.products.list,
        headers: { 'Content-Type': 'application/json' },
        data: product,
    });
};

export const deleteProduct = async (id: string): Promise<any> => {
    return fetch({
        method: 'DELETE',
        url: routes.products.single(id),
    });
};

export const getProductPictures = async (id: string): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.products.picture(id),
        headers: { 'Content-Type': 'application/json' },
    })
};

export const uploadProductPictures = async (id: string, formData: FormData): Promise<ProductDto> => {
    return fetch({
        method: 'POST',
        url: routes.products.picture(id),
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    });
};

export const deleteProductPicture = async (id: string): Promise<any> => {
    return fetch({
        method: 'DELETE',
        url: routes.products.picture(id),
    });
};