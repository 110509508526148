import { getProductPictures } from "@/communication/product";
import PictureUpload from "@/components/pictureUpload";
import { ProductDto } from "@/global/dtos/product.dto";
import { FileWithPreview } from "@/types/product";
import { useEffect } from "react";
import { useState } from "react";
import { FunctionComponent } from "react";
import useLoading from "@/hooks/useLoading";
import {
  StyledComission,
  StyledContainer,
  StyledCreatorDetails,
  StyledDescription,
  StyledDetails,
  StyledImgContainer,
  StyledName,
  StyledPricing,
  StyledSoldTag,
} from "./style";
import { getProfilePicture } from "@/communication/user";
import useUserStore from "@/store/user";
import { RoleCodes } from "@/global/roles";

interface Props {
  product: ProductDto;
}

const ProductCard: FunctionComponent<Props> = ({ product }) => {
  const [productPictures, setProductPictures] = useState<FileWithPreview[]>([]);
  const { showLoading, hideLoading } = useLoading();
  const [sellerPicture, setSellerPicture] = useState<string | undefined>(
    undefined
  );
  const { role } = useUserStore();

  useEffect(() => {
    if (product.id) {
      showLoading();
      getProductPictures(product.id).then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProductPictures(pictures);
        hideLoading();
      });
      getProfilePicture(product.sellerId)
        .then((res: any) => {
          if (res && res.data && res.data.length > 0) {
            setSellerPicture(res.data[0].url);
          }
          hideLoading();
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  }, []);
  return (
    <StyledContainer>
      <StyledImgContainer>
        <PictureUpload
          onRemove={() => {}}
          onFileChange={() => {}}
          showActions={false}
          thumbnails={false}
          pictureList={productPictures}
          disabled={product.sold}
        />
        {/* {product.sold && <img alt="sold" src={SoldImg}></img>} */}
        {product.sold && <span>SOLD</span>}
      </StyledImgContainer>

      <StyledDetails>
        <StyledName>
          {product.brand?.name} - {product.name}
        </StyledName>
        <StyledDescription>
          {product.description && `${product.description.substring(0, 100)}...`}
        </StyledDescription>
        <StyledPricing>
          <div
            style={{ textDecoration: "line-through" }}
          >{`$${((parseInt(product.price) * 100) / (100 - product.discount)).toFixed(2)}`}</div>
          <div>{`$${product.price}`}</div>
        </StyledPricing>
        <StyledComission>
          {`Potential commission: $${product.expectedCommission}`}
          {product.sold && <StyledSoldTag>Sold</StyledSoldTag>}
        </StyledComission>

        {role === RoleCodes.ADMIN ? (
          <StyledCreatorDetails>
            {sellerPicture ? <img src={sellerPicture}></img> : null}
            <span>{`${product.seller.firstName} ${product.seller.lastName}`}</span>
          </StyledCreatorDetails>
        ) : null}
      </StyledDetails>
    </StyledContainer>
  );
};

export default ProductCard;
